<template>
  <div v-if="authenticated">
    <sidebar />
    <div class="relative md:ml-64">
      <admin-navbar />
      <header-stats />
      <div class="px-4 md:px-10 mx-auto w-full -m-24">
        <router-view />
        <!-- <footer-admin /> -->
      </div>
    </div>
  </div>
  <div v-else class="flex justify-center items-center h-screen">
    <div class="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
      <h2 class="text-2xl mb-6 text-center">Enter Password</h2>
      <div class="mb-4">
        <label class="block text-gray-700 text-sm font-bold mb-2" for="password">
          Password
        </label>
        <input
          v-model="password"
          class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          id="password"
          type="password"
          placeholder="Enter your password"
        >
      </div>
      <div class="flex items-center justify-between">
        <button
          @click="authenticate"
          class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          type="button"
        >
          Submit
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import AdminNavbar from "@/components/Navbars/AdminNavbar.vue";
import Sidebar from "@/components/Sidebar/Sidebar.vue";
import HeaderStats from "@/components/Headers/HeaderStats.vue";
// import FooterAdmin from "@/components/Footers/FooterAdmin.vue";

export default {
  name: "admin-layout",
  components: {
    AdminNavbar,
    Sidebar,
    HeaderStats,
    // FooterAdmin,
  },
  data() {
    return {
      password: "",
      authenticated: false,
      correctPassword: "M&M=argent" // Change this to your actual password
    };
  },
  methods: {
    authenticate() {
      if (this.password === this.correctPassword) {
        this.authenticated = true;
      } else {
        alert("Incorrect password");
        this.password = "";
      }
    }
  }
};
</script>
