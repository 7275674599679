<template>
  <div>
    <!-- <index-navbar /> -->

    <section class="min-h-full header relative items-center flex" :style="{ minHeight: `${windowHeight}px`, backgroundImage: `url(${patternVue})`, backgroundSize: 'cover' }">
      <div class="container mx-auto items-center flex flex-wrap min-h-full">
        
        <!-- Text and Form Container -->
        <div class="w-full md:flex md:justify-between marging lg:p-16 p-4 lg:bg-opacity-75 bg-opacity-40 bg-gray-100">
          <!-- Text on top for mobile and tablet, left for desktop -->
          <div class="md:w-8/12 lg:w-6/12 xl:w-6/12 px-4 md:mb-0">
            <div class="container">
              <h2 class="font-semibold text-4xl text-black">
                Libérez votre potentiel solaire
              </h2><br>
                <p class="text-lg text-xl leading-relaxed text-black">Nous prenons en charge l'intégralité des démarches administratives pour votre installation solaire, vous permettant ainsi de profiter d'une transition vers l'énergie solaire sans aucun coût initial. Profitez des aides gouvernementales disponibles et passez à une énergie propre et durable en toute tranquillité d'esprit, sans frais supplémentaires pour vous.</p>
              <br>
            </div>
          </div>

          <!-- Form on the right for desktop, below for mobile and tablet -->
          <div class="md:w-4/12 lg:w-6/12 xl:w-6/12 px-4">
            <form @submit.prevent="submitForm">
        <!-- Form fields with v-model -->
        <div class="mb-2">
          <label for="nom" class="block text-black font-semibold">Nom</label>
          <input required type="text" id="nom" name="nom" v-model="formData.name"
            class="w-full border-green-300 rounded-md px-4 py-2 focus:outline-none focus:border-green-400"
            placeholder="Votre nom">
        </div>
        <div class="mb-2">
          <label for="prenom" class="block text-black font-semibold">Prénom</label>
          <input required type="text" id="prenom" name="prenom" v-model="formData.surname"
            class="w-full border-green-300 rounded-md px-4 py-2 focus:outline-none focus:border-green-400"
            placeholder="Votre prénom">
        </div>
        <div class="mb-2">
          <label for="code-postal" class="block text-black font-semibold">Code Postal</label>
          <input required pattern="\d{5}" type="text" id="code-postal" name="code-postal" v-model="formData.postalCode"
            class="w-full border-green-300 rounded-md px-4 py-2 focus:outline-none focus:border-green-400"
            placeholder="Code Postal">
        </div>
        <div class="mb-2">
          <label for="telephone" class="block text-black font-semibold">Téléphone</label>
          <input required type="tel" id="telephone" name="telephone" v-model="formData.phone"
            class="w-full border-green-300 rounded-md px-4 py-2 focus:outline-none focus:border-green-400"
            placeholder="Numéro de téléphone">
        </div>
        <div class="mt-8">
          <!-- Submit button -->
          <button type="submit"
            class="w-full bg-green-500 text-white font-semibold px-4 py-2 rounded-md hover:bg-green-600 transition duration-300">Envoyer</button>
        </div>
      </form>
          </div>
        </div>
      </div>
    </section>

        <!-- <section class="mt-48 md:mt-40 pb-40 relative bg-blueGray-100">            
    </section> -->

    <!-- <section class="block relative z-1 bg-blueGray-600">

    </section> -->

    <!-- <section class="py-20 bg-blueGray-600 overflow-hidden">
      
    </section> -->

    <!-- <section class="pb-16 bg-blueGray-200 relative pt-32">

    </section> -->

    <!-- <footer-component /> -->
  </div>
</template>
<script>
// import IndexNavbar from "@/components/Navbars/IndexNavbar.vue";
// import FooterComponent from "@/components/Footers/Footer.vue";
import patternVue from "@/assets/img/panneau7.jpeg";
import componentBtn from "@/assets/img/component-btn.png";
import componentProfileCard from "@/assets/img/component-profile-card.png";
import componentInfoCard from "@/assets/img/component-info-card.png";
import componentInfo2 from "@/assets/img/component-info-2.png";
import componentMenu from "@/assets/img/component-menu.png";
import componentBtnPink from "@/assets/img/component-btn-pink.png";
import documentation from "@/assets/img/documentation.png";
import login from "@/assets/img/login.jpg";
import profile from "@/assets/img/profile.jpg";
import landing from "@/assets/img/landing.jpg";

let uri = window.location.search.substring(1); 
let params = new URLSearchParams(uri);
  
export default {
  data() {
    return {
      patternVue,
      componentBtn,
      componentProfileCard,
      componentInfoCard,
      componentInfo2,
      componentMenu,
      componentBtnPink,
      documentation,
      login,
      profile,
      landing,
      windowHeight: window.innerHeight,
      formData: { // Object to hold form data
        origin: params.get("o") ? params.get("o").toString() : 0,
        name: "",
        surname: "",
        postalCode: "",
        phone: ""
      }
    };
  },
  methods: {
    submitForm() {
      // Send form data to API
      fetch('https://subventionsolaire.fr:3000/api/data', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(this.formData)
      })
      .then(response => {
        if (response.ok) {
          alert('Merci, nos équipes vont vous rapeller prochainement.');
          // Reset form data
          this.formData = {
            name: "",
            surname: "",
            postalCode: "",
            phone: ""
          };
          this.$gtag.event('conversion', { send_to: 'AW-16532026701/Cki0CJGS_aYZEM3qiss9' })
        } else {
          alert('Erreur lors de l\'envoi du formulaire veuillez réesseyer plus tard');
        }
      })
      .catch(error => {
        console.error('Error submitting form:', error);
        alert('An error occurred while submitting the form.');
      });
    }
  }
};
</script>
