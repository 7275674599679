<template>
  <!-- Header -->
  <div class="relative bg-blue-600 md:pt-32 pb-32 pt-12">
    <div class="px-4 md:px-10 mx-auto w-full">
      <div>
      </div>
    </div>
  </div>
</template>

<script>
// import CardStats from "@/components/Cards/CardStats.vue";

export default {
  // components: {
  //   CardStats,
  // },
};
</script>
